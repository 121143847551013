import { getList } from '@/apis/page'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useCurrentPages = defineStore('pages', () => {

  const currentPage = ref(1)
  const limitPage = ref(40)
  const pages = computed(() => result.value?.pages?.data)
  const pageOptions = computed(() => {
    if (pages.value && pages.value.length > 0) {
      return pages.value.map((page: any) => ({
        label: page.attributes.Title,
        value: page.id
      }))
    }
  })
  const pagination = computed(() => result.value?.pages?.meta?.pagination)
  const { refetch: fetchPages, result } = getList({
    page: currentPage.value,
    pageSize: limitPage.value,
  })


  return {
    pageOptions,
    pagination,
    fetchPages
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCurrentPages, import.meta.hot))